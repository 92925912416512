<!--
 * @Author: your name
 * @Date: 2022-03-09 21:14:24
 * @LastEditTime: 2022-06-22 11:27:29
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\views\buysuccess\index.vue
-->
<template>
  <div class="buysuccess">
    <div class="img-box">
      <img src="@/assets/images/selectpack/20.png" alt="" class="img">
      <p class="toast">购买成功</p>
    </div>
    <p class="p1">{{info.buy_name}}</p>
    <p class="p2" :class="{smallp2:screenHeight<=667}">￥{{info.buy_price}}</p>
    <div class="content">
      <div class="cardbox">
        <span class="title">流量</span>
        <span class="text">{{info.buy_name}}</span>
      </div>
      <div class="cardbox">
        <span class="title">类型</span>
        <span class="text">4G</span>
      </div>
      <div class="cardbox">
        <span class="title">账户余额</span>
        <span class="text">{{info.c_balance}}</span>
      </div>
      <div class="cardbox">
        <span class="title">到期时间</span>
        <span class="text">{{info.c_extime}}</span>
      </div>
    </div>
    <div class="next-step" @click="nextstep">下一步</div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      info:"", // 个人用户的所有信息
      screenHeight:  document.body.clientHeight, //实时高度
    }
  },
  mounted(){
    this.getInfo()
  },
  methods:{
    
    // 跳走到个人中心
    nextstep(){
      // 如果是第一次购买
      if(this.$route.query.is_first == 1){
       window.location.href= this.info.real_url 
      }else{
        this.$router.push("/index?dn="+this.$route.query.iccid+"&openid="+this.info.openid)
      }
    },
    getInfo(){
      let data = {
        iccid:this.$route.query.iccid
      }
      this.iccidInfo({data:data}).then(res =>{
        if(res.data.code == 1){
          this.info = res.data.info

        }

      })
    }
  }
}
</script>
<style lang="less" scoped>
.buysuccess{
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  .img-box{
    width: 100%;
    height: 76px;
    padding-top: 60px;
    margin-bottom: 20px;
    .img{
      width: 48px;
      height: 48px;
      display: block;
      margin: 0 auto;
    }
    .toast{
      font-size: 16px;
      font-weight: 600;
      color: #3ac87d;
      margin-top: 3px;
      text-align: center;
    }
  }
  .p1{
    text-align: center;
    font-size: 16px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .p2{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 50px;
    font-weight: 400;
    text-align: center;
  }
  .smallp2{
    margin: 20px auto;
  }
  .content{
    margin: 20px 10px 0;
    background: white;
    box-sizing: border-box;
    line-height: 50px;
    font-size: 13px;
    box-sizing: border-box;
    padding-bottom: 20px;
    .cardbox{
      height: 50px;
      .title{
        display: inline-block;
        width: 25%;
        text-indent: 10px;
        color: #c9c9c9;
      }
      .text{
        width: 75%;
        border-bottom: 1px solid #eee;
        display: inline-block;
      }
    }
  }
  .next-step{
    margin: 30px auto 0;
    width: 210px;
    height: 46px;
    border-radius: 22px;
    text-align: center;
    line-height: 46px;
    color: #2dc575;
    border: 1px solid #2dc575;
  }
}
</style>